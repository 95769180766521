import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from "../images/REVIVE-logo.svg"
import instaImage from "../images/svg/insta-ic.svg"

const Header = ({ siteTitle }) => (
  <header className="header py-2 py-lg-3">
   <div className="container">
    <Link to="/" className="d-none d-lg-flex justify-content-center align-items-center text-decoration-none pt-lg-5">
     <img src={logo} alt=""/>
   </Link>
   <nav className="navbar navbar-expand-lg">
     <Link to="/" className="d-flex d-lg-none">
       <img src={logo} alt=""/>
     </Link>
     <button className="navbar-toggler collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse " id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mt-4 mb-2 mb-lg-0">
        <li className="nav-item"><Link  to="/" activeClassName="active" className="nav-link" >Autod</Link></li>
        <li className="nav-item"><Link  to="/about" activeClassName="active" className="nav-link">Meist</Link></li>
        <li className="nav-item"><a href="https://www.instagram.com/revive.cars" className="nav-link"><img src={instaImage} alt=""/></a></li>
      </ul>
    </div>
    </nav>
  </div>
</header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
